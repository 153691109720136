<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <div class="d-flex align-items-center justify-content-between">
        <h3>Tickets</h3>
        <button class="lab-btn lab-btn-info ml-1" @click="$router.push({ name: 'new-ticket' })">Open Ticket</button>
      </div>
      <p>Your tickets are listed below</p>
    </div>
    
    <div class="dashboard-card-body cs-scroll-style">
      <table class="dashboard-table">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Status</th>
            <th scope="col" class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tickets" :key="item.id">
            <td>{{item.title}}</td>
            <td>
              <span v-if="item.isClosed">
                <span v-show="item.ticketStatus === 4" class="lab-badge lab-badge-mellow-melon">Closed</span>
              </span>
              <span v-else>
                <span v-show="item.ticketStatus === 0" class="lab-badge lab-badge-just-peachy">Pending</span>
                <span v-show="item.ticketStatus === 1" class="lab-badge lab-badge-minted-elegance">Answered</span>
              </span>
            </td>
            <!-- <td><span class="lab-badge lab-badge-minted-elegance">Answered</span></td> -->
            <td class="text-center">
              <span class="table-action table-action-edit lab-p-8">
                <feather-icon
                  @click="$router.push({ name: 'ticket-detail', params: { id: item.ticketNo } })"
                  icon="ArrowRightIcon"
                  class="cursor-pointer lab-text-steel"
                  size="25"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  methods: {
    fetchTickets() {
      this.$store.dispatch('ticket/fetchTickets', {});
    },
  },
  computed: {
    tickets() {
      return this.$store.getters['ticket/getTicketList'];
    },
  },
  created() {
    this.fetchTickets();
  },
};
</script>

<style scoped lang="scss">

</style>
