<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-delete"
      scrollable
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      hide-footer
      hide-header
      size="sm"
    >
      <div class="confirm-modal">
        <div class="title mt-2 mb-2">
          <h4>
            <warning-alert-icon />
            WARNING!
          </h4>
        </div>
        <div class="message">
          <p>
            This action is <b>IRREVERSIBLE</b>. If you delete this
            kit, you will no longer be able to access to the 
            services attached to it.
          </p>
        </div>
        <div class="text-right mt-2">
          <button class="lab-btn lab-btn-lg lab-btn-info" type="submit" @click.prevent="confirmPasswordModal"> I UNDERSTAND </button>
          <button class="lab-btn lab-btn-lg ml-2 lab-btn-outline-mellow" @click="hiddenModal()"> Cancel </button>
        </div>
      </div>
    </b-modal>
    <confirm-password-popup v-if="confirmModalObject.showModal" :modalObject="confirmModalObject"/>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service';
import ApiService from '@/common/api.service';

import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';
import WarningAlertIcon from '@/layouts/icons/WarningAlertIcon.vue';

import ConfirmPasswordPopup from '@/components/ConfirmPasswordPopup.vue';

export default {
  components: {
    WarningAlertIcon,
    ConfirmPasswordPopup,
  },
  props: {
    type: {
      type: String,
    },
    modalObject: {
      type: Object,
    },
  },
  data() {
    return {
      confirmModalObject: {
        item: null,
        showModal: false,
        onSuccess: this.handleOk,
      },
    }
  },
  methods: {
    initialModal() {
      this.$refs['modal-delete'].show();
    },
    confirmPasswordModal() {
      this.confirmModalObject.item = this.modalObject.item;
      this.confirmModalObject.showModal = true;
    },
    handleOk(callback) {
      const kit = this.modalObject.item;

      ApiService.post('order/kits/delete', kit, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });

            this.modalObject.onSuccess();
            this.hiddenModal();
            callback(true);
          } else {
            AlertService.error(this, response.message)
          }
          this.modalObject.item.password = null;
        });
    },
    hiddenModal() {
      this.$refs['modal-delete'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
  .title {
    text-align: center;
    h4 {
      color: var(--lab-carbon-fiber);
      font-size: 1.4rem;
    }
  }

  .message p {
    color: var(--lab-stieglitz-silver);
    font-size: 0.8rem;
  }
}

.dark-layout .confirm-modal .title h4 {
  color: var(--lab-mellow-melon);
}

.dark-layout .confirm-modal .message p {
  color: var(--lab-doctor);
}
</style>
