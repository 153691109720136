<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <h3>My Kits</h3>
      <p>The files you uploaded are listed below</p>
    </div>
    
    <div class="dashboard-card-body cs-scroll-style">
      <table class="dashboard-table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">File</th>
            <th scope="col">Service</th>
            <th scope="col" class="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in kits" :key="item.guid">
            <td>{{item.resultTitle}}</td>
            <td>{{item.resultDescription}}</td>
            <td>{{ item.serviceName }}</td>
            <td class="text-right">
              <span class="table-action table-action-edit">
                <feather-icon
                  @click="editKit(item)"
                  icon="EditIcon"
                  class="cursor-pointer lab-text-steel"
                  size="16"
                />
              </span>
              <span class="table-action">
                <feather-icon
                  @click="deleteKit(item)"
                  icon="Trash2Icon"
                  class="cursor-pointer lab-text-steel"
                  size="16"
                />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <edit-kit-modal :modalObject="editObject" v-if="editObject.showModal"/>
    <delete-kit-modal :modalObject="deleteObject" v-if="deleteObject.showModal"/>
  </div>
</template>

<script>
import EditKitModal from './actions/kits/EditKitModal.vue';
import DeleteKitModal from './actions/kits/DeleteKitModal.vue';

export default {
  components: {
    EditKitModal,
    DeleteKitModal,
  },
  data() {
    return {
      editObject: {
        item: null,
        showModal: false,
        onSuccess: this.fetchKits,
      },
      deleteObject: {
        item: null,
        showModal: false,
        onSuccess: this.fetchKits,
      },
    }
  },
  methods: {
    fetchKits() {
      this.$store.dispatch('orders/fetchKits', {});
    },

    editKit(kit) {
      this.editObject.item = { ...kit };
      this.editObject.showModal = true;
    },

    deleteKit(kit) {
      this.deleteObject.item = { ...kit };
      this.deleteObject.showModal = true;
    },
  },
  computed: {
    kits() {
      /* eslint-disable */
      return this.$store.getters['orders/getKits'].map(item => {
        if (item.resultDescription) {
          item.resultDescription = item.resultDescription.length > 20 ? item.resultDescription.slice(0, 20) + '...' : item.resultDescription;
        }
        
        return item;
      });
    },
  },
  created() {
    this.fetchKits();
  },
}
</script>

<style scoped lang="scss">
.dashboard-card-body {
  max-height: 300px;
  overflow: auto!important;
  overflow-x: hidden!important;
}

</style>
