<template>
  <div class="main-body  mx-auto">
    <div v-if="pendingOrder">
      <b-alert
        variant="warning"
        show
      >
        <div class="alert-body">
          <span><strong>note!</strong> Your results are under process. This can take up to 3 business day, we appreciate your patience.</span>
        </div>
      </b-alert>
      <alert :message="'When your results are ready, you can see them under the G25 tab.'" variant="warning"/>
    </div>
    
    <div class="dashboard">
      <b-row>
        <b-col class="mb-2" cols="12" xl="6" md="12">
          <dashboard-kits></dashboard-kits>
        </b-col>
        <b-col class="mb-2" cols="12" xl="6" md="12">
          <dashboard-order></dashboard-order>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xl="7" md="7">
          <dashboard-store></dashboard-store>
        </b-col>
        <b-col cols="12" xl="5" md="5">
          <dashboard-tickets></dashboard-tickets>
        </b-col>
      </b-row>
    </div>
    <div class="dashboard-mobile">
      <mobile-dashboard-services />
      <mobile-dashboard-others  class="mt-4"/>
      <mobile-dashboard-store />
    </div>
  </div>
</template>

<script>
import DashboardTickets from './dashboards/main-dashboard/DashboardTickets.vue'
import DashboardStore from './dashboards/main-dashboard/DashboardStore.vue'
import DashboardOrder from './dashboards/main-dashboard/DashboardOrder.vue'
import DashboardKits from './dashboards/main-dashboard/DashboardKits.vue'
import MobileDashboardServices from './dashboards/main-dashboard/mobile/MobileDashboardServices.vue'
import MobileDashboardStore from './dashboards/main-dashboard/mobile/MobileDashboardStore.vue'
import MobileDashboardOthers from './dashboards/main-dashboard/mobile/MobileDashboardOthers.vue'

export default {
  components: {
    DashboardKits,
    DashboardTickets,
    DashboardStore,
    DashboardOrder,
    MobileDashboardServices,
    MobileDashboardStore,
    MobileDashboardOthers,
  },
  data() {
    return {
    }
  },
  methods: {
    loadProducts() {
      this.$store.dispatch('products/fetchProducts', {});
    },
  },
  computed: {
    pendingOrder() {
      return this.$store.getters['orders/getOrders'].find(x => x.orderStatus === 1);
    },
  },
  created() {
    this.loadProducts();
  },
}
</script>

<style lang="scss" scoped>
.main-body {
  max-width: 1300px !important;
}

.title-header-text {
  color: #898989 !important;
}

@media screen and (max-width: 769px) {
  .dashboard {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .dashboard-mobile {
    display: none !important;
  }
}
</style>
