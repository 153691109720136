<template>
  <div class="dashboard-card">
    <div class="dashboard-card-title">
      <h3>Order History</h3>
      <p>Your orders are listed below</p>
    </div>
    
    <div class="dashboard-card-body cs-scroll-style">
      <table class="dashboard-table">
        <thead>
          <tr>
            <th scope="col">Service</th>
            <th scope="col">Order ID</th>
            <th scope="col">Date</th>
            <th scope="col">Price</th>
            <th scope="col" class="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in orders" :key="item.id">
            <td>{{item.orderTitle}}</td>
            <td>{{item.orderNo}}</td>
            <td>{{item.orderDateLabel }}</td>
            <td>{{item.currencyUnit + ''+ item.subtotal}}</td>
            <td class="text-center">
              <span v-show="item.orderStatus === 3" class="lab-badge lab-badge-minted-elegance">Completed</span>
              <span v-show="item.orderStatus === 1" class="lab-badge lab-badge-just-peachy">Pending</span>
              <span v-show="item.orderStatus === 2" class="lab-badge lab-badge-lago-blue">Processing</span>
              <span v-show="item.orderStatus === 4" class="lab-badge lab-badge-mellow-melon">Cancelled</span>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    }
  },
  methods: {
    fetchOrders() {
      this.$store.dispatch('orders/fetchOrders', {});
    },
  },
  computed: {
    orders() {
      const orders = this.$store.getters['orders/getOrders'];
      
      return orders;
    },
    pendingOrder() {
      return this.$store.getters['orders/getOrders'].find(x => x.orderStatus === 1);
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>

<style scoped lang="scss">
.dashboard-card-body {
  max-height: 300px;
  overflow: auto!important;
  overflow-x: hidden!important;
}
</style>
